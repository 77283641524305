import {Backdrop, Box, CircularProgress} from '@mui/material';
import React, {FC} from 'react';

export interface PreloaderProps {
    open: boolean;
    children: React.ReactNode;
}

export const Preloader: FC<PreloaderProps> = ({open, children}) => {
    return (
        <Box style={{position: "relative"}}>
            <Backdrop open={open} style={{position: "absolute", zIndex: 999}}>
                <CircularProgress/>
            </Backdrop>
            {children}
        </Box>

    )
}
