import {FC} from "react";
import {Divider, Grid, Tooltip, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {LoginPanelGridItem} from "./LoginPanelStyled";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LoginButton from "./LoginButton";
import {Preloader} from "../../../components/Preloader/Preloader";
import MobileHiddenElement from "../../../components/MobileHiddenElement";
import {HeaderTypography} from "../../../components/TypographyStyled";
import {HomePageGrid, HomePageGridItem, HomePagePaper} from "../HomePageStyled";

interface LoginPanelProps {
    isLoading: boolean,
    username: string,
    level: number,
    userId: number,
    disabledButton: boolean,
    onLogIn(): void,
}

const LoginPanel: FC<LoginPanelProps> = ({isLoading, level, username, userId, disabledButton, onLogIn}) => {
    return (
        <HomePageGrid alignSelf={"center"} xs={12}>
            <Preloader open={isLoading}>

                <HomePagePaper>
                    <HomePageGridItem>
                        <Grid container justifyContent={"center"}>
                            <Typography fontWeight={600} variant={"overline"} color={grey[700]}>
                                Please log in to manage your account
                            </Typography>
                        </Grid>
                        <Divider/>
                        <Grid container justifyContent={"center"}>
                            <LoginPanelGridItem>
                                <HeaderTypography>Username</HeaderTypography>
                                <Tooltip title={username}>
                                    <Typography fontWeight={"bold"}>{username}</Typography>
                                </Tooltip>
                            </LoginPanelGridItem>
                            <LoginPanelGridItem>
                                <HeaderTypography>Id</HeaderTypography>
                                <Typography fontWeight={"bold"}>{userId}</Typography>
                            </LoginPanelGridItem>
                            <MobileHiddenElement mobileType={"S"}>
                                <LoginPanelGridItem>
                                    <HeaderTypography>lvl</HeaderTypography>
                                    <Typography fontWeight={"bold"}>{level}</Typography>
                                </LoginPanelGridItem>
                            </MobileHiddenElement>
                            <LoginPanelGridItem>
                                <LoginButton
                                    variant={"contained"}
                                    color={"secondary"}
                                    onClick={onLogIn}
                                    disabled={disabledButton}
                                    endIcon={<ArrowForwardIosIcon/>}/>
                            </LoginPanelGridItem>
                        </Grid>
                    </HomePageGridItem>
                </HomePagePaper>
            </Preloader>
        </HomePageGrid>
    )
}

export default LoginPanel