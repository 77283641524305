import OncServerException from "./OncServerException";
import axios from "axios";

export const runOrThrowOncServerException = async <T, >(callback: () => Promise<T>): Promise<T> => {
    try {
        return await callback()
    } catch (e) {
        if (axios.isAxiosError(e)) {
            throw OncServerException.createFromAxiosException(e)
        }
        throw new OncServerException("Unknown error", 500)
    }
}

export const runCatching = async <T, >(callback: () => Promise<T>, onFailure?: (ex: OncServerException) => void) => {
    try {
        await callback()
    } catch (e) {
        let err = new OncServerException("Unknown error", 500)
        if (e instanceof OncServerException) err = e

        if (typeof onFailure === 'function') {
            onFailure(err)
        }
    }
};