import {AxiosError} from "axios";

export default class OncServerException extends Error {
    timestamp?: number | null
    hash?: string | null
    statusCode: number | undefined

    constructor(message: string, statusCode: number | undefined, timestamp?: number | null, hash?: string | null) {
        super(message);
        this.name = OncServerException.name
        this.statusCode = statusCode
        this.timestamp = timestamp;
        this.hash = hash;

        Object.setPrototypeOf(this, OncServerException.prototype);
    }

    static createFromAxiosException(ex: AxiosError) {
        let response = ex.response
        if (response !== undefined) {
            return new OncServerException(
                response.data.message == null ? "Failed to fetch response" : response.data.message,
                response.status,
                response.data.hash,
                response.data.timestamp,
            )
        }
        return new OncServerException("Connection refused", 111)
    }
}
