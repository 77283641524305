import UserService from "../service/UserService";
import ApiUserRepository from "../repository/ApiUserRepository";
import UserDetails from "../model/UserDetails";


const userService = new UserService(new ApiUserRepository())

export default class UserController {

    static getUser(): Promise<UserDetails> {
        return userService.getUser()
    }

    static redeemPromoCode(promoCode: string): Promise<void> {
        return userService.redeemPromoCode(promoCode)
    }

    static updateReferralCode(referralCode: string): Promise<void> {
        return userService.updateReferralCode(referralCode)
    }

    static removeReferralCode(): Promise<void> {
       return userService.removeReferralCode()
    }
}