import React, {FC} from "react";
import {FullPageSizeGrid, Main} from "./PageLayoutStyled";
import {Grid} from "@mui/material";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

interface PageLayoutProps {
    children: React.ReactNode;
}

const PageLayout: FC<PageLayoutProps> = ({children}) => {
    return (
        <FullPageSizeGrid container direction={"column"} alignContent={"center"} justifyContent={"center"} wrap={"nowrap"}>
            <Grid item alignItems={"flex-start"} >
                <Header/>
            </Grid>
            <Grid item marginY={"auto"}>
                <Main>
                    {children}
                </Main>
            </Grid>
            <Grid item alignItems={"flex-end"}>
                <Footer/>
            </Grid>
        </FullPageSizeGrid>
    )
}

export default PageLayout