import {
    BrowserRouter as Router,
    Route, Switch
} from "react-router-dom";
import {FC} from "react";
import {HomePage} from "../view/homePage";


const Routes: FC = () =>{
    return(
        <Router>
            <Switch >
                <Route component={HomePage}/>
            </Switch >
        </Router>
    )
}

export default Routes;