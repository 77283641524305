import UserDetails from "../model/UserDetails";
import UserRepository from "./UserRepository";
import restApiClient from "../utils/RestApiClient";

export default class ApiUserRepository implements UserRepository {

    async getUserDetails(): Promise<UserDetails> {
        const result = await restApiClient.getClient().get<UserDetails>("/game-user")
        return result.data
    }

    async redeemPromoCode(promoCode: string):Promise<void> {
        await restApiClient.getClient().post("/game-user/promo-codes/redeem", {promoCodeName: promoCode})
    }

    async updateReferralCode(referralCode: string): Promise<void> {
        await restApiClient.getClient().patch("/game-user/referral-codes", {referralCode: referralCode})
    }

    async removeReferralCode(): Promise<void> {
        await restApiClient.getClient().delete("/game-user/referral-codes")
    }
}