import {Box, Grid, styled} from "@mui/material";

export const FullPageSizeGrid = styled(Grid)`
  width: 100vw;
  height: 100vh;
  min-width: 320px;
  min-height: 500px;
`;

export const Main = styled(Box)`
  display: flex;
  height: 100%;

  //@media (max-width: 768px) {
  //  min-height: 350px;
  //}
`