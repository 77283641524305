import axios, {AxiosInstance, AxiosRequestHeaders} from "axios";


class RestApiClient {
    private client?: AxiosInstance;
    private readonly baseUrl: string | undefined;

    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
    }

    getClient = () => {
        let headers: AxiosRequestHeaders = {
            Accept: "application/json",
            "Accept-Language": "en"
        };
        const apiToken = sessionStorage.getItem("x-token")
        let deviceFPHeader = sessionStorage.getItem("x-device-fp")

        if (apiToken) headers.Authorization = `Bearer ${apiToken}`;
        if (deviceFPHeader) headers["Device-FP"] = deviceFPHeader

        this.client = axios.create({
            baseURL: this.baseUrl,
            timeout: 15000,
            headers: headers,
        });
        return this.client;
    };
}


export default new RestApiClient()