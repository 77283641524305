import React, {FC} from "react";
import UserProfile from "./UserProfile/UserProfile";
import {
    Accordion,
    AccordionDetails,
    Collapse, Divider,
    Grid,
    GridProps, List, ListItem,
    Typography
} from "@mui/material";
import {HomePageGrid, HomePagePaper} from "../HomePageStyled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummaryCentered from "../../../components/Accordion/AccordionSummaryCentered";
import {grey} from "@mui/material/colors";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UserCodeInputPanel from "./UserCodeInputPanel/UserCodeInputPanel";
import {Preloader} from "../../../components/Preloader/Preloader";
import {UserController} from "../../../../domain";
import {useSnackbar} from "notistack";
import {runCatching} from "../../../../domain/exception/ExceptionHandling";

interface ItemProps extends GridProps {
    title: string;
    description: string;
}

const Item: FC<ItemProps> = ({...props}) => {
    return (
        <Grid container justifyContent={"space-between"} style={{cursor: "pointer"}}>
            <Grid item marginY={"8px"}>
                <Typography fontWeight={"bold"}>{props.title}</Typography>
                <Typography variant={"body2"} color={grey[700]}>{props.description}</Typography>
            </Grid>
            <Grid item marginY={"8px"}>
                <Grid container direction={"column"} justifyContent={"center"} alignSelf={"center"}>
                    <ArrowForwardIosIcon fontSize={"small"} style={{marginTop: "16px"}} color={"action"}/>
                </Grid>
            </Grid>
        </Grid>
    )
}


interface CodeInputState {
    showCodeInput: boolean,
    title: string,
    description: string,

    onSubmit(input: string): void
}

interface UserManagementPanelProps {
    referralCode: string | null,
    username: string,
    level: number,
    userId: number,
}

const UserManagementPanel: FC<UserManagementPanelProps> = ({referralCode, username, level, userId}) => {

    const {enqueueSnackbar} = useSnackbar();

    const [accordionCollapsed, setAccordionCollapsed] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)
    const [codeInputState, setCodeInputState] = React.useState<CodeInputState>({
        showCodeInput: false,
        title: "",
        description: "",
        onSubmit: () => {
            //empty body due to allowing the function to be passed
        }
    })

    const resetCodeInputState = () => {
        setCodeInputState({
                ...codeInputState,
                showCodeInput: false,
                title: "",
                description: "",
            }
        )
    }

    const showCodeInputAndSetDetails = (title: string, description: string, onSubmit: (input: string) => void) => {
        setCodeInputState({
            showCodeInput: true,
            description: description,
            title: title,
            onSubmit: onSubmit
        })
    }

    const updatePartnerCode = async (code: string) => {
        await runCatching(async () => {
            setShowLoader(true)
            await UserController.updateReferralCode(code)
            enqueueSnackbar(`Success! U new partner code is ${code}`, {variant: "success"})
        }, (data) => {
            enqueueSnackbar(data.message, {variant: "error"})
        })
        setShowLoader(false)

    }

    const redeemPromoCode = async (code: string) => {
        await runCatching(async () => {
            setShowLoader(true)
            await UserController.redeemPromoCode(code)
            enqueueSnackbar(`Success! Your reward will be in your mailbox in a moment `, {variant: "success"})
        }, (data) => {
            enqueueSnackbar(data.message, {variant: "error"})
        })
        setShowLoader(false)

    }

    return (
        <HomePageGrid alignSelf={"center"} xs={12} marginY={"8px"}>
            <Preloader open={showLoader}>
                <HomePagePaper>
                    <UserProfile userId={userId} level={level} username={username}/>
                    <Collapse in={!codeInputState.showCodeInput}>
                        <List>
                            <Divider hidden={accordionCollapsed}/>
                            <Collapse in={!accordionCollapsed} style={{width: "100%"}}>
                                <ListItem
                                    onClick={() => window.open("https://moondaygames.com/polityka-prywatnosci", "_blank")}>
                                    <Item title={"Agreements"} description={"Read the documents you've signed"}/>
                                </ListItem>
                                <Divider/>
                                <ListItem
                                    onClick={() => window.open("https://moondaygames.com/support/", "_blank")}>
                                    <Item title={"Support"} description={"Write to us if you need help"}/>

                                </ListItem>
                            </Collapse>
                            <Divider/>
                            <ListItem disablePadding>
                                <Accordion elevation={0} style={{width: "100%"}} onChange={(event, expanded) => {
                                    setAccordionCollapsed(expanded)
                                }}>
                                    <AccordionSummaryCentered
                                        expandIcon={<ExpandMoreIcon color={"action"} fontSize={"large"}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Typography fontWeight={"bold"}>More</Typography>
                                    </AccordionSummaryCentered>
                                    <Divider/>
                                    <AccordionDetails
                                        onClick={() => showCodeInputAndSetDetails("Promo Codes", "Enter promo code", input => redeemPromoCode(input))}>
                                        <Item title={"Promo Codes"} description={"Insert free promo codes"}/>
                                    </AccordionDetails>
                                    <Divider/>
                                    <AccordionDetails
                                        onClick={() => showCodeInputAndSetDetails("Partner codes", "Enter partner code", input => updatePartnerCode(input))}>
                                        <Item title={"Partner Codes"}
                                              description={`Your current partner code: ${referralCode === null ? "" : referralCode}`}/>
                                    </AccordionDetails>
                                </Accordion>
                            </ListItem>
                        </List>
                    </Collapse>
                    <Collapse in={codeInputState.showCodeInput}>
                        <Divider/>
                        <UserCodeInputPanel
                            title={codeInputState.title}
                            description={codeInputState.description}
                            onSubmit={input => codeInputState.onSubmit(input)}
                            onArrowBackClick={resetCodeInputState}
                        />
                    </Collapse>
                </HomePagePaper>
            </Preloader>
        </HomePageGrid>
    )
}

export default UserManagementPanel