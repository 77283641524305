import {Button, ButtonProps, useMediaQuery, useTheme} from "@mui/material";
import {FC} from "react";


const ResponsiveButton: FC<ButtonProps> = ({...props}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (<Button {...props} size={isSmallScreen ? "small" : "large"}/>);
}

export default ResponsiveButton;