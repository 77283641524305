import {Grid, GridProps, styled} from "@mui/material";
import * as React from "react";


export const LoginPanelGridItem = styled(({...props}) =>
    <Grid item alignSelf={"center"} textAlign={"center"} {...props}/>)<GridProps>
    `
      width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 600px) {
        width: 86px;
      }
    `

