import {FC} from "react";
import {HomePageGridItem} from "../../HomePageStyled";
import {Grid, Tooltip, Typography} from "@mui/material";
import {HeaderTypography} from "../../../../components/TypographyStyled";
import {UserProfileGridItem} from "./UserProfileStyled";

interface UserProfileProps{
    userId: number,
    username?: string,
    level: number,
}


const UserProfile: FC<UserProfileProps> = ({userId, username= "Noname", level}) => {
    return (
        <HomePageGridItem paddingX={2}>
            <Grid container justifyContent={"space-between"}>
                <UserProfileGridItem>
                    <HeaderTypography>Username</HeaderTypography>
                    <Tooltip title={username}>
                        <Typography fontWeight={"bold"}>{username}</Typography>
                    </Tooltip>
                </UserProfileGridItem>
                <UserProfileGridItem>
                    <HeaderTypography>Id</HeaderTypography>
                    <Typography fontWeight={"bold"}>{userId}</Typography>
                </UserProfileGridItem>
                <UserProfileGridItem>
                    <HeaderTypography>lvl</HeaderTypography>
                    <Typography fontWeight={"bold"}>{level}</Typography>
                </UserProfileGridItem>
            </Grid>
        </HomePageGridItem>
    )
}

export default UserProfile