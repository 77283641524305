import React from 'react';
import ReactDOM from 'react-dom';
import App from './ui/App';

const {ClientJS} = require('clientjs');
const client = new ClientJS();

let url = new URL(window.location.href)
let currentToken = sessionStorage.getItem('x-token')
let currentDeviceFP = sessionStorage.getItem("x-device-fp")

let token = url.searchParams.get('token')
if (token !== null && token !== currentToken) {
    sessionStorage.setItem("x-token", token)
}

if (currentDeviceFP === null){
    sessionStorage.setItem("x-device-fp", client.getFingerprint())
}

url.searchParams.delete('token')
window.history.replaceState({}, document.title, url);

ReactDOM.render(<React.StrictMode> <App/> </React.StrictMode>, document.getElementById('root'));

