import {FC} from "react";
import {ButtonProps} from "@mui/material";
import ResponsiveButton from "../../../components/ResponsiveButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


const LoginButton: FC<ButtonProps> = ({variant, color, onClick, disabled}) => {
    return (
        <ResponsiveButton
            style={{textTransform: "none"}}
            variant={variant}
            color={color}
            onClick={onClick}
            disabled={disabled}
            endIcon={<ArrowForwardIosIcon/>}
        >
            Log in
        </ResponsiveButton>
    )

}
export default LoginButton