import React, {FC, useEffect} from "react";
import {Grid} from "@mui/material";
import LoginPanel from "./LoginPanel/LoginPanel";
import UserManagementPanel from "./UserManagementPanel/UserManagementPanel";
import UserDetails from "../../../domain/model/UserDetails";
import {UserController} from "../../../domain";
import {useSnackbar} from "notistack";
import {runCatching} from "../../../domain/exception/ExceptionHandling";


const HomePage: FC = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isLoggedIn, setIsLoggedIn] = React.useState(false)
    const [user, setUser] = React.useState<UserDetails>(new UserDetails(0, 0, "Unknown", null))
    const [showLoader, setShowLoader] = React.useState<boolean>(true)
    const [loginButtonDisabled, setLoginButtonDisabled] = React.useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            await runCatching(async () => {
                let result = await UserController.getUser()
                setUser(result)
            }, ex => {
                setLoginButtonDisabled(true)
                enqueueSnackbar(ex.message, {variant: "error"})
            })

            setShowLoader(false)
        }
        fetchData()

    }, [])

    const changeLogInState = () => {
        setIsLoggedIn((prev) => !prev)
    }

    return (
        <Grid container justifyContent={"center"}>
            {!isLoggedIn ?
                <LoginPanel
                    isLoading={showLoader}
                    level={user.level}
                    username={user.username}
                    userId={user.id}
                    disabledButton={loginButtonDisabled}
                    onLogIn={() => changeLogInState()}
                />
                :
                <UserManagementPanel
                    referralCode={user.referralCode}
                    level={user.level}
                    username={user.username}
                    userId={user.id}
                />
            }
        </Grid>
    )
}
export default HomePage