import React, {FC} from "react";
import {HomePageGridItem} from "../../HomePageStyled";
import {Box, Button, Grid, IconButton, TextField, Typography} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {grey} from "@mui/material/colors";

interface UserCodeInputPanelProps {
    title: string,
    description: string,

    onSubmit(input: string): void,

    onArrowBackClick(): void,
}

const UserCodeInputPanel: FC<UserCodeInputPanelProps> = ({title, description, onSubmit, onArrowBackClick}) => {
    const [code, setCode] = React.useState("")
    const [textFieldError, setTextFieldError] = React.useState(false)

    const handleCodeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCode(event.target.value);
    };


    const handleArrowBackClick = () => {
        setCode("")
        onArrowBackClick()
    }

    const handleSubmitButtonClick = () => {
        if (isTextFieldValid()) {
            setTextFieldError(false)
        } else {
            setTextFieldError(true)
            return
        }
        onSubmit(code)
        setCode("")
    }

    const isTextFieldValid = (): boolean => {
        return code.length > 0
    }

    return (
        <HomePageGridItem>
            <Grid container direction={"column"} padding={"20px"} alignItems={"center"}>
                <Box position={"relative"} width={"100%"}>
                    <Box position={"absolute"} left={0}>
                        <IconButton disableFocusRipple disableRipple onClick={handleArrowBackClick}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                    </Box>
                </Box>
                <Grid item>
                    <Typography fontWeight={"bold"}>{title}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant={"body2"} color={grey[700]}>{description}</Typography>
                </Grid>
                <Grid item width={"100%"} marginTop={"40px"}>
                    <TextField fullWidth={true} variant={"outlined"} value={code}
                               error={textFieldError}
                               onChange={(event) => handleCodeChange(event)}/>
                </Grid>
                <Grid item marginTop={"40px"}>
                    <Button onClick={() => handleSubmitButtonClick()} style={{
                        minWidth: "128px",
                        letterSpacing: "0.01em",
                        fontWeight: "600",
                        fontSize: "medium",
                        textTransform: "none",
                    }} variant={"contained"} size={"small"}
                            color={"secondary"}>Submit</Button>
                </Grid>
            </Grid>
        </HomePageGridItem>
    )
}

export default UserCodeInputPanel