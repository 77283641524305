import {FC} from "react";
import WindowDimension from "./WindowDimension";
import * as React from "react";

interface MobileHiddenElementProps {
    mobileType: "S" | "M" | "L",
    children?: React.ReactNode
}

const MobileHiddenElement: FC<MobileHiddenElementProps> = ({mobileType, children}) => {
    const {width} = WindowDimension();

    const shouldShowElement = () => {
        switch (mobileType) {
            case "S":
                return width <= 320;
            case "M":
                return width <= 375;
            case "L":
                return width <= 425;
        }
    }

    return (
        <>
            {!shouldShowElement() && children}
        </>
    )
}

export default MobileHiddenElement