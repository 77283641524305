import {FC} from "react";
import {Typography} from "@mui/material";
import {HeaderGrid} from "./HeaderStyked";
import {grey} from "@mui/material/colors";

const Header: FC = () => {
    return(
        <HeaderGrid container direction={"column"} justifyContent={"center"}>
            <Typography variant={"title1"} align={"center"} color="textSecondary">One Night Casino</Typography>
            <Typography variant={"subtitle3"} align={"center"} color={grey[500]}>Account Management</Typography>
        </HeaderGrid>
    )
}

export default Header