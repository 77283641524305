import {FC} from "react";
import {AccordionSummary, AccordionSummaryProps} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    content: {
        justifyContent: "center"
    }
});

const AccordionSummaryCentered: FC<AccordionSummaryProps> = ({...props})=>{
    const classes = useStyles();
    return(
        <AccordionSummary
            classes={{ content: classes.content }}
            {...props}
        />
    )
}

export default AccordionSummaryCentered