import {createTheme} from "@mui/material";
import {grey, purple} from "@mui/material/colors";
import React from "react";

declare module '@mui/material/styles' {
    interface TypographyVariants {
        title1: React.CSSProperties;
        subtitle3: React.CSSProperties;

    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        title1?: React.CSSProperties;
        subtitle3?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        title1: true;
        subtitle3: true;
    }
}


export const defaultTheme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: purple[900]
        },
        secondary: {
            main: purple[900]
        },
        text: {
            secondary: grey["A100"],
        }
    },
    typography: {
        title1:{
            fontFamily: "'Roboto', sans-serif, serif",
            fontSize: "2rem",
            fontWeight: 550,
        },
        subtitle3:{
            fontFamily: "'Roboto', sans-serif, serif",
            fontSize: "1.3rem",
            fontWeight: 550,
        }
    }
})