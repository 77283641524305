import {FC} from "react";
import {Grid} from "@mui/material";

const Footer: FC = () => {
    return(
        <Grid container justifyContent={"center"}>
            <Grid item marginTop={"16px"}>
                <img width={"150px"} src={require('../../../assets/moonday_logo.png').default}/>
            </Grid>
        </Grid>
    )
}

export default Footer