export default class UserDetails {
    id: number;
    level: number;
    username: string;
    referralCode: string | null

    constructor(id: number, level: number, nickname: string, referralCode: string | null) {
        this.id = id
        this.level = level
        this.username = nickname
        this.referralCode = referralCode
    }

}