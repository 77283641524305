import React, {FC} from 'react';
import {ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";
import {defaultTheme} from "./styles/defaultTheme";
import PageLayout from "./components/PageLayout/PageLayout";
import Routes from "./routes/Routes";
import {SnackbarProvider} from "notistack";

const App: FC = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{vertical: "bottom", horizontal: "right",}}
                hideIconVariant={false}
            >
                <PageLayout>
                    <Routes/>
                </PageLayout>
            </SnackbarProvider>

        </ThemeProvider>
    );
}

export default App;
