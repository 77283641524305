import {Grid, GridProps, styled} from "@mui/material";
import * as React from "react";

export const UserProfileGridItem = styled(({...props}) =>
    <Grid item alignSelf={"center"} textAlign={"center"} {...props}/>)<GridProps>
    `
      width: 132px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 16px;

      @media (max-width: 600px) {
        width: 86px;
      }
    `