import UserRepository from "../repository/UserRepository";
import UserDetails from "../model/UserDetails";
import {runOrThrowOncServerException} from "../exception/ExceptionHandling";

export default class UserService {

    private userRepository: UserRepository;

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository;
    }

    getUser(): Promise<UserDetails> {
        return runOrThrowOncServerException(() => {
            return this.userRepository.getUserDetails()
        })

    }

    redeemPromoCode(promoCode: string): Promise<void> {
        return runOrThrowOncServerException(() => {
            return this.userRepository.redeemPromoCode(promoCode)
        })
    }

    updateReferralCode(referralCode: string): Promise<void> {
        return runOrThrowOncServerException(() => {
            return this.userRepository.updateReferralCode(referralCode)
        })
    }

    removeReferralCode(): Promise<void> {
        return runOrThrowOncServerException(() => {
            return this.userRepository.removeReferralCode()
        })
    }
}