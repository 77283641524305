import {Grid, GridProps, Paper, styled} from "@mui/material";
import * as React from "react";

export const HomePageGrid = styled(Grid)`
  max-width: 544px !important;
  height: max-content;
  border-radius: 16px;
  overflow: hidden;
`

export const HomePagePaper = styled(Paper)`
  min-height: 64px;
  overflow: hidden;
`

export const HomePageGridItem = styled(({...props}) =>
    <Grid item {...props}/>)<GridProps>
    `
      min-height: 64px;
      margin-bottom: 12px;
      margin-top: 12px;
    `